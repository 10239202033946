import React from "react"
import styled from "styled-components/macro"
import { Link, navigate } from "gatsby"
import { Helmet } from "react-helmet"
import * as Yup from "yup"
import { Formik } from "formik"
import { useDispatch } from "react-redux"
import { signIn } from "../../../redux/actions/authActions"
import Config from "../../../config/Config"

import {
  Avatar,
  Button,
  Paper,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core"
import { spacing } from "@material-ui/system"
import { Alert as MuiAlert } from "@material-ui/lab"
import RobotKittensLogo from "../../../images/robotkittens-logo.jpeg"
import {
  ROUTE_APP_DASHBOARD,
  ROUTE_AUTH_COMPLETE_PASSWORD,
  ROUTE_AUTH_RESET_PASSWORD,
} from "../../../constants"

const Alert = styled(MuiAlert)(spacing)

const TextField = styled(MuiTextField)(spacing)

const Wrapper = styled(Paper)`
  padding: ${props => props.theme.spacing(6)}px;
  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)}px;
  }
`

let validation = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  password: Yup.string().max(255).required("Password is required"),
})

if (Config.loginType === "username") {
  validation = Yup.object().shape({
    username: Yup.string()
      .max(255)
      .required("Username is required"),
    password: Yup.string().max(255).required("Password is required"),
  })
}

const BigAvatar = styled(Avatar)`
  width: 92px;
  height: 92px;
  text-align: center;
  margin: 0 auto ${props => props.theme.spacing(5)}px;
`

function SignIn() {
  const dispatch = useDispatch()

  return (
    <Wrapper>
      <Helmet title="Sign In" />
      <BigAvatar alt="Robot Kittens" src={RobotKittensLogo} />

      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Welcome to the {Config.name}
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        Sign in to your account to continue
      </Typography>

      <Formik
        initialValues={{
          email: "",
          username: "",
          password: "",
          submit: false,
        }}
        
        validationSchema={validation}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            const user = await dispatch(
              signIn({ username:values.username,email: values.email, password: values.password })
            )

            if (
              user.challengeName &&
              user.challengeName === "NEW_PASSWORD_REQUIRED"
            ) {
              navigate(ROUTE_AUTH_COMPLETE_PASSWORD)
            } else {
              navigate(ROUTE_APP_DASHBOARD)
            }
          } catch (error) {
            const message = error.message || "Something went wrong"

            setStatus({ success: false })
            setErrors({ submit: message })
            setSubmitting(false)
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={1} severity="warning">
                {errors.submit}
              </Alert>
            )}
            {Config.loginType === "email" &&
            <TextField
              type="email"
              name="email"
              label="Email Address"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            }
             {Config.loginType === "username" &&
            <TextField
              type="username"
              name="username"
              label="Username"
              value={values.username}
              error={Boolean(touched.username && errors.username)}
              fullWidth
              helperText={touched.username && errors.username}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            }
            <TextField
              type="password"
              name="password"
              label="Password"
              value={values.password}
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={handleChange}
              mt={2}
              mb={5}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              Sign in
            </Button>
            <Button
              component={Link}
              to={ROUTE_AUTH_RESET_PASSWORD}
              fullWidth
              color="primary"
            >
              Forgot password
            </Button>
          </form>
        )}
      </Formik>
    </Wrapper>
  )
}

export default SignIn
