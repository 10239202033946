import React from "react"
import { Router } from "@reach/router"
import Layout from "../layouts/Auth"
import {
  Login,
  CompletePassword,
  ResetPassword,
  ResetPasswordSubmit,
} from "../components/pages/auth"
import store from "../redux/store/index"
import { Provider } from "react-redux"
import Amplify from "aws-amplify"
import awsconfig from "../aws-exports"
import { CssBaseline } from "@material-ui/core"
import Page404 from "../components/pages/Page404"
import {
  ROUTE_AUTH_LOGIN,
  ROUTE_AUTH_COMPLETE_PASSWORD,
  ROUTE_AUTH_RESET_PASSWORD,
  ROUTE_AUTH_RESET_PASSWORD_SUBMIT,
} from "../constants"

Amplify.configure(awsconfig)

const Page = () => (
  <Provider store={store}>
    <CssBaseline />
    <Layout>
      <Router>
        <Login path={ROUTE_AUTH_LOGIN} />
        <CompletePassword path={ROUTE_AUTH_COMPLETE_PASSWORD} />
        <ResetPassword path={ROUTE_AUTH_RESET_PASSWORD} />
        <ResetPasswordSubmit path={ROUTE_AUTH_RESET_PASSWORD_SUBMIT} />
        <Page404 default />
      </Router>
    </Layout>
  </Provider>
)

export default Page
