import React from "react"
import { navigate } from "gatsby"
import styled from "styled-components/macro"
import { Helmet } from "react-helmet"
import * as Yup from "yup"
import { Formik } from "formik"
import { useDispatch } from "react-redux"
import { resetPasswordSubmit } from "../../../redux/actions/authActions"
import { useSelector } from "react-redux"
import { isBrowser } from "../../../utils"

import {
  Button,
  Paper,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core"
import { spacing } from "@material-ui/system"
import { Alert as MuiAlert } from "@material-ui/lab"
import {
  ROUTE_APP_DASHBOARD,
  ROUTE_AUTH_RESET_PASSWORD,
} from "../../../constants"

const Alert = styled(MuiAlert)(spacing)

const TextField = styled(MuiTextField)(spacing)

const Wrapper = styled(Paper)`
  padding: ${props => props.theme.spacing(6)}px;

  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)}px;
  }
`

function ResetPasswordSubmit() {
  const dispatch = useDispatch()
  const email = useSelector(state => state.authReducer.reset)

  if (!email && isBrowser()) {
    navigate(ROUTE_AUTH_RESET_PASSWORD)
  }

  return (
    <Wrapper>
      <Helmet title="Change Your Password" />

      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Change Your Password
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        Enter your reset code and your new password
      </Typography>

      <Formik
        initialValues={{
          code: "",
          password: "",
          confirmPassword: "",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          code: Yup.string().required("Required"),
          password: Yup.string()
            .min(8, "Must be at least 8 characters")
            .max(255)
            .required("Required"),
          confirmPassword: Yup.string().when("password", {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
              [Yup.ref("password")],
              "Both password need to be the same"
            ),
          }),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await dispatch(
              resetPasswordSubmit({
                email: email,
                password: values.password,
                code: values.code,
              })
            )
            navigate(ROUTE_APP_DASHBOARD)
          } catch (error) {
            const message = error.message || "Something went wrong"

            setStatus({ success: false })
            setErrors({ submit: message })
            setSubmitting(false)
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={1} severity="warning">
                {errors.submit}
              </Alert>
            )}
            <TextField
              type="text"
              name="code"
              label="Reset Code"
              value={values.code}
              error={Boolean(touched.code && errors.code)}
              fullWidth
              helperText={touched.passwocoderd && errors.code}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="password"
              name="password"
              label="New Password"
              value={values.password}
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="password"
              name="confirmPassword"
              label="Confirm Password"
              value={values.confirmPassword}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              fullWidth
              helperText={touched.confirmPassword && errors.confirmPassword}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              Reset
            </Button>
          </form>
        )}
      </Formik>
    </Wrapper>
  )
}

export default ResetPasswordSubmit
